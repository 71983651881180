import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { RiBookOpenLine, RiChatSmile3Line, RiFileList3Line, RiMenuFill, RiSearchLine } from 'react-icons/ri';

import { gaEvents } from '~/utils/analytics';
import { NavigatorItem } from '~/components/Navigator';
import { IApplicationState } from '~/redux-tools/store';
import { TabIcon, LoginIcon, ProfileIcon } from '~/assets';
import { FeaturesEnum, MenuLabelEnum, OperationModesEnum } from '~/interfaces/enums';

import { useHasFeatureAccess, useUserAuthenticationStatus } from '..';

interface UseNavigationList {
  goNavigation: NavigatorItem[];
  inStoreNavigation: NavigatorItem[];
}

export default function useNavigationList(): UseNavigationList {
  const { settings, isViewMode } = useSelector((state: IApplicationState) => state.establishment);
  const { isOperatorMode, mode: localOrder } = useSelector((state: IApplicationState) => state.localOrders);

  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const [hasUserSignUpAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.UserSignUp });
  const [hasNewDeliveryAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.NewDeliveryMenu });
  const [hasTableOnlinePayment] = useHasFeatureAccess({ featureId: FeaturesEnum.TableOnlinePayment });

  const isInStoreWithAccount = useMemo(() => !!localOrder && localOrder !== OperationModesEnum.kiosk, [localOrder]);

  const orderStatusEnabled = useMemo(
    () => settings?.mm_order_status_check_enabled,
    [settings?.mm_order_status_check_enabled]
  );

  const hasOrderOption = useMemo(() => !!settings?.mm_whatsapp_phone_number, [settings?.mm_whatsapp_phone_number]);

  const shouldDisplayOrderStatus = useMemo(
    () => orderStatusEnabled && hasOrderOption,
    [hasOrderOption, orderStatusEnabled]
  );

  const isAbrahaoStore = useMemo(() => settings?.is_abrahao, [settings?.is_abrahao]);
  const shouldDisplayProfile = useMemo(() => !!hasUserSignUpAccess, [hasUserSignUpAccess]);

  const inStoreNavigation: NavigatorItem[] = useMemo(() => {
    const navigationList: NavigatorItem[] = [
      {
        linkTo: 'menu',
        dataTest: 'btn-menu',
        label: MenuLabelEnum.menu,
        icon: <RiBookOpenLine size={24} />
      },
      {
        linkTo: 'busca',
        dataTest: 'btn-search',
        gaEvent: gaEvents.search,
        label: MenuLabelEnum.search,
        icon: <RiSearchLine size={24} />
      }
    ];

    if (isAbrahaoStore && !isViewMode && !isOperatorMode && !!settings?.mm_call_waiter_enabled) {
      navigationList.push({
        linkTo: 'garcom',
        dataTest: 'btn-garcom',
        label: MenuLabelEnum.waiter,
        icon: <RiChatSmile3Line size={24} />
      });
    }

    if (
      isInStoreWithAccount &&
      !isViewMode &&
      !isOperatorMode &&
      (isAbrahaoStore ? !!settings?.mm_is_the_check_visible : true)
    ) {
      navigationList.push({
        dataTest: 'btn-tab',
        label: MenuLabelEnum.myTab,
        icon: <RiFileList3Line size={24} />,
        linkTo: hasTableOnlinePayment ? 'mesa' : 'conta'
      });
    }

    if (shouldDisplayProfile && !isAbrahaoStore && !isViewMode && !isOperatorMode) {
      navigationList.push({
        linkTo: 'profile',
        dataTest: 'btn-profile',
        icon: isUserAuthenticated ? <ProfileIcon /> : <LoginIcon />,
        label: isUserAuthenticated ? MenuLabelEnum.profile : MenuLabelEnum.signin,
        gaEvent: isUserAuthenticated ? gaEvents.toolbarProfile : gaEvents.toolbarSignIn
      });
    }

    if (isAbrahaoStore) {
      navigationList.push({
        linkTo: 'more',
        icon: <RiMenuFill />,
        dataTest: 'btn-more',
        label: MenuLabelEnum.more
      });
    }

    return navigationList;
  }, [
    isViewMode,
    isAbrahaoStore,
    isOperatorMode,
    isUserAuthenticated,
    isInStoreWithAccount,
    shouldDisplayProfile,
    hasTableOnlinePayment,
    settings?.mm_call_waiter_enabled,
    settings?.mm_is_the_check_visible
  ]);

  const goNavigation: NavigatorItem[] = useMemo(() => {
    const navigationList: NavigatorItem[] = [
      {
        linkTo: 'menu',
        dataTest: 'btn-menu',
        icon: <RiBookOpenLine />,
        label: MenuLabelEnum.menu
      },
      {
        linkTo: 'busca',
        dataTest: 'btn-search',
        label: MenuLabelEnum.search,
        icon: <RiSearchLine size={24} />
      }
    ];

    if (shouldDisplayOrderStatus && !isOperatorMode) {
      navigationList.push({
        count: '1',
        icon: <TabIcon />,
        linkTo: 'order/status',
        dataTest: 'btn-status-order',
        label: MenuLabelEnum.orderStatus
      });
    }

    if (hasNewDeliveryAccess && !isUserAuthenticated) {
      navigationList.push({
        linkTo: 'more',
        dataTest: 'btn-more',
        icon: <RiMenuFill />,
        label: MenuLabelEnum.more
      });
    }

    if (shouldDisplayProfile && !isAbrahaoStore && !isOperatorMode) {
      navigationList.push({
        linkTo: 'profile',
        dataTest: 'btn-profile',
        icon: isUserAuthenticated ? <ProfileIcon /> : <LoginIcon />,
        label: isUserAuthenticated ? MenuLabelEnum.profile : MenuLabelEnum.signin,
        gaEvent: isUserAuthenticated ? gaEvents.toolbarProfile : gaEvents.toolbarSignIn
      });
    }

    return navigationList;
  }, [
    isOperatorMode,
    isAbrahaoStore,
    isUserAuthenticated,
    hasNewDeliveryAccess,
    shouldDisplayProfile,
    shouldDisplayOrderStatus
  ]);

  return { goNavigation: goNavigation, inStoreNavigation: inStoreNavigation };
}
