import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { RiArrowLeftLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';

interface HeaderSecondaryProps {
  title: string;
  hideBack?: boolean;
  isSuggestion?: boolean;
  onBackAction?: () => void;
  changeRedirect?: () => void;
}

const HeaderSecondary: React.FC<HeaderSecondaryProps> = ({
  title,
  onBackAction,
  changeRedirect,
  hideBack = false,
  isSuggestion = false,
  ...rest
}) => {
  const history = useRouter();

  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  const onClick = (): void => {
    if (changeRedirect) {
      return changeRedirect();
    }

    if (onBackAction) {
      onBackAction();
    }

    history.push('/menu');
  };

  const handleDismiss = (): void => {
    history.push('/menu');
    return;
  };

  return (
    <S.Header {...rest}>
      {!isSuggestion && !hideBack && (
        <S.BackButton
          data-test="btn-back"
          onClick={(): void => onClick()}
          aria-label={getTranslation('$t(general.back, lowercase)')}
        >
          <RiArrowLeftLine size={24} color={theme.colors.textColor} />
        </S.BackButton>
      )}

      <S.TitleWrapper isSuggestion={isSuggestion}>
        <h1>{title}</h1>

        {isSuggestion && <S.Button onClick={handleDismiss}>{getTranslation('general.skip')}</S.Button>}
      </S.TitleWrapper>
    </S.Header>
  );
};

export default HeaderSecondary;
